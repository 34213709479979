import Model from './Model'

class PaymentMethod extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
        'last_succeeded_at',
        'last_failed_at',
        'inactive_at',
      ],
    })
  }
}

export default PaymentMethod
