import axios from './axios'
import PaymentMethod from 'Models/PaymentMethod'
import { map, mapKeys, snakeCase } from 'lodash'

async function getAll (params) {
  params = mapKeys(params, (value, key) => snakeCase(key))
  // console.log('Params', params)

  return axios.get(`/payment_methods`, { params })
    .then(response => map(response.data, newPaymentMethod))
    .catch(console.error)
}

async function create (payload) {
  return axios.post(`/payment_methods`, { payment_method: payload })
    .then(response => map(response.data, newPaymentMethod))
    .catch(console.error)
}

function newPaymentMethod (pm) {
  return new PaymentMethod(pm)
}

export default {
  getAll,
  create,
}
