import StoreInstances, { getStoreFor } from 'Stores/StoreInstances'
import BaseStore from 'Stores/base_store/BaseStore'
import { action } from 'mobx'
import PaymentMethods from 'DataServices/PaymentMethods'

const STORE_TYPE = 'PaymentMethods'

export function getPaymentMethodsStoreFor (param, preload) {
  return getStoreFor(PaymentMethodsStore, STORE_TYPE, param, false, preload)
}

export class PaymentMethodsStore extends BaseStore {
  constructor (seedData) {
    const opts = {
      paginate: false,
    }
    super(STORE_TYPE, seedData, opts)
  }

  @action
  _fetchAll () {
    let payload = {
      ...this._filter,
    }
    return PaymentMethods.getAll(payload)
  }

  @action
  create (payload) {
    return PaymentMethods.create(payload)
      .then(pm => {
        StoreInstances._propagateChanges(STORE_TYPE, [pm])
        return pm
      })
  }
}
