<template>
  <div class="tw-py-2">
    <div>
      <h2 class="tw-text-xl tw-mb-1">Virtual Bank Accounts</h2>
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        <div v-for="account in virtualBankAccounts"
            :key="account.id"
            class="tw-bg-gray-100 tw-rounded-md tw-shadow">
          <div class="tw-flex tw-flex-col">
            <div class="tw-flex-row tw-flex tw-justify-between tw-bg-gray-400 tw-rounded-t-md tw-px-2 tw-py-2">
              <h3 class="tw-text-lg tw-font-bold tw-leading-7">{{ account.name }}</h3>
              <ks-icon-button v-if="showSettingsButton" @click="openSettingsModal(account.id)">
                <svgicon class="ks-icon-size-xxs" :icon="'ks-icon-gear'" />
              </ks-icon-button>
            </div>
            
            <div class="tw-p-2">
              <div class="flex justify-between">
                <span class="tw-text-gray-600">Balance:</span>
                <span class="tw-font-medium">${{ account.balance }}</span>
              </div>

              <div class="flex justify-between">
                <span class="tw-text-gray-600">Pending:</span>
                <span class="tw-font-medium">${{ account.pending_amount }}</span>
              </div>

              <div class="flex justify-between">
                <span class="tw-text-gray-600">Distribution Account:</span>
                <span class="tw-font-medium">{{ account.distributable_bank_account_name || 'None' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-mt-4 tw-px-2 tw-py-2 tw-max-w-xs tw-bg-gray-100 tw-rounded-md tw-shadow">
      <h2 class="tw-text-lg tw-mb-1">New Virtual Bank Account</h2>
      <ks-input-field type="text" v-model="newAccountName" label="Name" :$v="$v.newAccountName"/>
      <ks-button label="Create Virtual Bank Account" @click="createNewAccount" :disabled="$v.$invalid" />
    </div>
  </div>
</template>

<script>
import { getVirtualBankAccountsStoreFor } from 'Stores/VirtualBankAccountsStore'
import ModalService, { modalNames } from 'Services/ModalService'
import KsIconButton from 'Components/KsIconButton'
import svgicon from 'vue-svgicon'
import KsInputField from 'Components/form/KsInputField'
import KsButton from 'Components/form/KsButton'
import { required } from 'vuelidate/lib/validators'
import { sortBy } from 'lodash'

export default {
  name: 'VirtualBankAccounts',
  components: {
    KsIconButton,
    svgicon,
    KsInputField,
    KsButton,
  },

  props: {
    ownable_type: {
      type: String,
      required: true,
      validator: function(value) {
        return ['Agency', 'User', 'Business'].includes(value)
      }
    },
    ownable_id: {
      type: Number,
      required: true
    }
  },
  validations: {
    newAccountName: {
      required,
    }
  },

  data() {
    return {
      vbasStore: getVirtualBankAccountsStoreFor({ 
        ownable_id: this.ownable_id,
        ownable_type: this.ownable_type
      }),
      showSettingsButton: true,
      newAccountName: '',
    }
  },

  fromMobx: {
    virtualBankAccounts() {
      return sortBy(this.vbasStore.allRecords, 'created_at')
    }
  },
  methods: {
    openSettingsModal (accountId) {
      ModalService.open(modalNames.KOMMANDER_PAY_ACCOUNT_AGENCY_SETTINGS, { virtual_bank_account_id: accountId })
    },

    createNewAccount () {
      this.vbasStore.create(this.ownable_id, { name: this.newAccountName })
      .then(() => {
        this.newAccountName = ''
        this.vbasStore.reload()
      })
    }
  },
}
</script>

