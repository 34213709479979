import 'core-js/actual/get-iterator'
// Polyfills for v-tooltip
import 'core-js/actual/object/assign'
import 'core-js/actual/symbol'

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

// Polyfill for svgicon
if (isIE11) {
  require('innersvg-polyfill')
}

// Polyfill console functions for when IE devtools don't exist
if (!window.console || Object.keys(window.console).length === 0) {
  window.console = {
    log: function () {},
    info: function () {},
    error: function () {},
    warn: function () {},
  }
}

import Vue from 'vue'
import * as mobx from 'mobx'
import Movue from 'movue'
import Vuelidate from 'vuelidate'

import VTooltip from 'v-tooltip'

VTooltip.enabled = window.innerWidth > 768

Vue.use(VTooltip)

Vue.use(Movue, mobx)
Vue.use(Vuelidate)

import 'Styles/tailwind.sass.scss'

function registerGlobalComponents(app) {
  return app
}

const COMPONENT_MAP = new Map()

// IMPORTS
import VirtualBankAccountsManager from 'Components/general/VirtualBankAccountsManager.vue'
COMPONENT_MAP.set('VirtualBankAccountsManager', VirtualBankAccountsManager)

import PaymentMethodsManager from 'Components/payment_methods/PaymentMethodsManager.vue'
COMPONENT_MAP.set('PaymentMethodsManager', PaymentMethodsManager)


const toInstantiate = document.querySelectorAll('[data-vue-component]')
if (toInstantiate.length > 0) {
  toInstantiate.forEach(el => {
    const elAttrs = el.attributes
    const comp = COMPONENT_MAP.get(elAttrs['data-vue-component'].value)
    const props = elAttrs['data-props']?.value
    if (comp) {
      registerGlobalComponents(
        new Vue({
          el: el,
          render: h => h(comp, { props: props && JSON.parse(props) }),
        })
      )
      el.attributes.removeNamedItem('data-vue-component')
      el.attributes.removeNamedItem('data-props')
    }
  })
}



// FROM https://gist.github.com/leonid-chernyavskiy/a08395005da5eacf8f2f293ce544f594
// see https://github.com/vuelidate/vuelidate/issues/460
// and https://github.com/vuelidate/vuelidate/issues/874
// and https://github.com/vuelidate/vuelidate/issues/302

/*
 Solves $params === null (early loading) bug
 https://github.com/vuelidate/vuelidate/issues/302
 https://github.com/vuelidate/vuelidate/issues/874
 this patch adds window['vuelidate'].withParams, which is runtime proxy to the module's withParams function
 When using vuelidate this must be the first import:
 import * as vuelidate from 'vuelidate';
 window['_patchVuelidate'](vuelidate);
 Next function _must_ be first at entry point (webpack, ex. page entry point) - to be always at the commons chunk:
 */


//  (function() {
//   window['_patchVuelidate'] = function _patchVuelidate(module) {
//       if (window['vuelidate'].hasOwnProperty('_module')) {
//           window['vuelidate']._module = module;
//           window['vuelidate'] = module;
//       }
//   };

//   window['vuelidate'] = window['vuelidate'] || (function() {
//       function anotherFakeWithParams(paramsOrClosure, maybeValidator) {
//           if (typeof paramsOrClosure === 'object' && maybeValidator !== undefined) {
//               return maybeValidator;
//           }

//           return paramsOrClosure(function() {});
//       }

//       // Early loaded chunks will have this function;
//       // it is as proxy to the module's function, which is created by _patchVuelidate,
//       // or to the anotherFakeWithParams function
//       function dynamicWithParams() {
//           return (window['vuelidate'] && window['vuelidate'] !== fakeVuelidate
//               ? window['vuelidate'].withParams
//               : anotherFakeWithParams
//           ).apply(null, arguments);
//       }

//       const fakeVuelidate = {
//           _module: undefined,
//           withParams: dynamicWithParams,
//       };

//       return fakeVuelidate;
//   })();
// })();

// import * as vuelidate from 'vuelidate'
// console.log('vuelidate app', vuelidate)
// window['_patchVuelidate'](vuelidate)